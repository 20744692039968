import { createSlice } from '@reduxjs/toolkit';

// const initialReportState = {
//     dataLoading: false,
//     data: [],
//     totalCount: 0,
//     error: '',
// };
const initialReportState = {
    dataLoading: false,
    data: [],
    totalCount: 0,
    error: '',
    memberDetails: null, // Add memberDetails to the initial state
};

export const agentsReportSlice = createSlice({
    name: 'agentsReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const branchesReportSlice = createSlice({
    name: 'branchesReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const groupsReportSlice = createSlice({
    name: 'groupsReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const meetingsReportSlice = createSlice({
    name: 'meetingsReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const loansReportSlice = createSlice({
    name: 'loansReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const savingsReportSlice = createSlice({
    name: 'savingsReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const membersReportSlice = createSlice({
    name: 'membersReport',
    initialState: initialReportState,
    reducers: {
        catchError: (state, action) => {
            state.error = `error: ${action.payload.error}`;
            state.dataLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.dataLoading = true;
        },
        reportFetched: (state, action) => {
            const { data, totalCount } = action.payload;
            state.dataLoading = false;
            state.error = null;
            state.data = data;
            state.totalCount = totalCount;
        },
        memberDetailsFetched: (state, action) => {
            state.dataLoading = false;
            state.error = null;
            state.memberDetails = action.payload.memberDetails;
        },
        setSelectedMemberId: (state, action) => {
            state.selectedMemberId = action.payload.memberId;
        },
    },
});

export const {
    catchError,
    startCall,
    reportFetched,
    memberDetailsFetched,
    setSelectedMemberId,
} = membersReportSlice.actions;

export default membersReportSlice.reducer;