import { createSlice } from '@reduxjs/toolkit';

const initialGroupsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    groupForView: undefined,
    lastError: null,
    membersTotalCount: 0,
    members: null
};

const initialGroupMeetingsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    groupMeeting: {},
    groupForView: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState: initialGroupsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getGroupById
        groupFetched: (state, action) => {
            state.actionsLoading = false;
            state.groupForView = action.payload.groupForView;
            state.error = null;
        },
        // findGroups
        groupsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // fetch members
        groupMembersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.members = entities;
            state.membersTotalCount = totalCount;
        },
        // createGroup
        groupCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.group);
        },
        // updateGroup
        groupUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.group.id) {
                    return action.payload.group;
                }
                return entity;
            });
        },
        // deleteGroup
        groupDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteGroups
        groupsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // groupsUpdateState
        groupsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyGroups
        myGroupsFetched: (state, action) => {
            const {
                totalCount,
                entities,
                individual_applications,
            } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
    },
});

export const groupMeetingsSlice = createSlice({
    name: 'group-meetings',
    initialState: initialGroupMeetingsState,
    reducers: {
        // catch an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // start call
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // find meetings
        meetingsFetched: (state, action) => {
            const { totalCount, meetings } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = meetings;
            state.totalCount = totalCount;
        },
        selectedMeetings: (state, action) => {
            const { totalCount, meetings } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = meetings;
            state.totalCount = totalCount;
        },
        meetingFetched: (state, action) => {
            const { meeting } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.groupMeeting = meeting;
        },
    },
});
