import { createSlice } from "@reduxjs/toolkit";

const initialMembersState = {
    listLoading: false, // for list call type
    actionsLoading: false, // for action call type
    totalCount: 0,
    entities: null,
    memberForView: '',
    cohorts: null,
    lastError: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const membersSlice = createSlice({
    name: "members",
    initialState: initialMembersState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false; 
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getMemberById
        memberFetched: (state, action) => {
            state.actionsLoading = false;
            state.memberForView = action.payload.memberForView;
            state.error = null;
        },
        // findMembers
        membersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        cohortsFetched: (state, action) => {
            const { cohorts } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.cohorts = cohorts;
        },
        // createMember
        memberCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.member);
        },
        // updateMember
        memberUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.member.id) {
                    return action.payload.member;
                }
                return entity;
            });
        },
        // deleteMember
        memberDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteMembers
        membersDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        // membersUpdateState
        membersStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyMembers
        myMembersFetched: (state, action) => {
            const { totalCount, entities, individual_applications } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
    }
});
