import { createSlice } from '@reduxjs/toolkit';

const initialLendingsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lendingForEdit: undefined,
    lastError: null,
    installments: {},
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const lendingsSlice = createSlice({
    name: 'lendings',
    initialState: initialLendingsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getLendingById
        lendingFetched: (state, action) => {
            state.actionsLoading = false;
            state.lendingForEdit = action.payload.lendingForEdit;
            state.error = null;
        },
        // findLendings
        lendingsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createLending
        lendingCreated: (state, action) => {
            state.ewactionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.lending);
        },
        // updateLending
        lendingUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.lending.id) {
                    return action.payload.lending;
                }
                return entity;
            });
        },
        // deleteLending
        lendingDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.id !== action.payload.id,
            );
        },
        // deleteLendings
        lendingsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id),
            );
        },
        // lendingsUpdateState
        lendingsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        loanInstallmentsFetched: (state, action) => {
            state.actionsLoading = false;
            console.log('Payload received in reducer: ', action.payload);
            state.installments[action.payload.loanId] =
                action.payload.installments;
            state.memberDetails = action.payload.memberDetails;
            state.loanDetails = action.payload.loanDetails;
            state.totalDetails = action.payload.totalDetails;
            state.error = null;
        },
        loanStatementsFetched: (state, action) => {
            state.actionsLoading = false;
            console.log('Payload received in reducer: ', action.payload);
            state.installments[action.payload.loanId] =
                action.payload.installments;
            state.error = null;
        },
    },
});
