import { createSlice } from '@reduxjs/toolkit';

const initialFumState = {
    actionsLoading: false,
    amount: {
        formatted: '',
        actual: 0,
    },
    currency: '',
};

const initialQuickStatsState = {
    actionsLoading: false,
    currency: '',
    amountTransacted: {
        formatted: '',
        actual: 0,
    },
    bankAccounts: {
        formatted: '',
        actual: 0,
    },
    cashAtHandAccounts: {
        formatted: '',
        actual: 0,
    },
    transactionsCount: {
        formatted: '',
        actual: 0,
    },
};

const initialRecentActivityState = {
    actionsLoading: false,
    signups: [],
};

const depositsVsWithdrawalsState = {
    actionsLoading: false,
    data: [],
};

const initialLoansStatsState = {
    actionsLoading: false,
    currency: '',
    totalLoaned: {
        formatted: '',
        actual: 0,
    },
    amountRepayed: {
        formatted: '',
        actual: 0,
    },
    amountDefaulted: {
        formatted: '',
        actual: 0,
    },
};

const initialCommunicationsState = {
    actionsLoading: false,
    totalSms: 0,
    totalEmail: 0,
    data: [],
};

const initialGroupsSummaryState = {
    actionsLoading: false,
    AllGroups: 0,
    ActiveToday: 0,
    data: [],
};

const initialMeetingsState = {
    actionsLoading: false,
    meetings: [],
};

export const fumSlice = createSlice({
    name: 'fum',
    initialState: initialFumState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Funds Under Management
        fumFetched: (state, action) => {
            const { amount, currency } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currency = currency;
            state.amount = amount;
        },
    },
});

export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: initialMeetingsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}:${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        meetingsFetched: (state, action) => {
            const { meetings } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.meetings = meetings;
        },
    },
});

export const quickStatsSlice = createSlice({
    name: 'quickStats',
    initialState: initialQuickStatsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Quick Stats
        quickStatsFetched: (state, action) => {
            const {
                currency,
                amountTransacted,
                bankAccounts,
                transactionsCount,
                cashAtHandAccounts,
                welfareFunds,
                withdrawalsTransacted,
                averageMembership,
                dividentPayouts,
                totalSavings,
            } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currency = currency;
            state.amountTransacted = amountTransacted;
            state.bankAccounts = bankAccounts;
            state.transactionsCount = transactionsCount;
            state.cashAtHandAccounts = cashAtHandAccounts;
            state.welfareFunds = welfareFunds; 
            state.withdrawalsTransacted = withdrawalsTransacted;
            state.averageMembership = averageMembership;
            state.dividentPayouts = dividentPayouts;
            state.totalSavings = totalSavings;
        },
    },
});

export const recentActivitySlice = createSlice({
    name: 'recentActivity',
    initialState: initialRecentActivityState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Recent Activity
        recentActivityFetched: (state, action) => {
            const { signups } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.signups = signups;
        },
    },
});

export const depositsVsWithdrawalsSlice = createSlice({
    name: 'depositsVsWithdrawals',
    initialState: depositsVsWithdrawalsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Deposits Vs Withdrawals
        depositsVsWithdrawalsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.data = data;
        },
    },
});

export const loansStatsSlice = createSlice({
    name: 'loansStats',
    initialState: initialLoansStatsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Loans Stats
        loansStatsFetched: (state, action) => {
            const {
                currency,
                totalLoaned,
                amountRepayed,
                amountDefaulted,
            } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currency = currency;
            state.totalLoaned = totalLoaned;
            state.amountRepayed = amountRepayed;
            state.amountDefaulted = amountDefaulted;
        },
    },
});

export const communicationStatsSlice = createSlice({
    name: 'communicationStats',
    initialState: initialCommunicationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Stats
        communicationStatsFetched: (state, action) => {
            const { sms_count, email_count, activity } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.totalSms = sms_count;
            state.totalEmail = email_count;
            state.data = activity;
        },
    },
});

export const groupsSummaryStatsSlice = createSlice({
    name: 'groupSummaryStats',
    initialState: initialGroupsSummaryState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // Fetch Stats
        groupSummaryStatsFetched: (state, action) => {
            const { groups, active_today, activity } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.AllGroups = groups;
            state.ActiveToday = active_today;
            state.data = activity;
        },
    },
});
