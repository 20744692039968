import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from '../app/modules/Auth/_redux/authRedux';
import { rolesSlice } from '../app/modules/UserManagement/_redux/roles/rolesSlice';
import { usersSlice } from '../app/modules/UserManagement/_redux/users/usersSlice';
import {
    fumSlice,
    meetingsSlice,
    quickStatsSlice,
    recentActivitySlice,
    depositsVsWithdrawalsSlice,
    loansStatsSlice,
    communicationStatsSlice,
    groupsSummaryStatsSlice,
} from '../app/data-widgets/_redux/widgetsSlice';
import {
    groupTypesSlice,
    countriesSlice,
    groupInfoSlice,
    membersInfoSlice,
    groupContributionsSlice,
    groupLoansSlice,
    groupAccountsSlice,
    groupConfirmSlice,
    banksSlice,
    bankBranchesSlice,
} from '../app/modules/Onboard/_redux/onboardSlice';
import { membersSlice } from '../app/modules/Members/_redux/membersSlice';
import {
    branchesOptionsSlice,
    branchesSlice,
} from '../app/modules/Branches/_redux/branchesSlice';
import {
    groupsSlice,
    groupMeetingsSlice,
} from '../app/modules/Groups/_redux/groupsSlice';

import { loanApplicationsSlice } from "../app/modules/Loans/_redux/loanApplications/loanApplicationsSlice";
import { loanProductsSlice } from "../app/modules/Loans/_redux/loanProducts/loanProductsSlice";
import { lendingsSlice } from "../app/modules/Loans/_redux/lendings/lendingsSlice";


import { agentsSlice } from '../app/modules/Agents/_redux/agentsSlice';
import {
    checkinSlice,
    systemProfileSlice,
    systemServicesSlice,
    systemThemeSlice,
    systemUsersSlice,
    systemSettingsSlice,
    countiesSlice,
} from '../app/modules/Setup/_redux/setupSlice';
import {
    agentsReportSlice,
    branchesReportSlice,
    groupsReportSlice,
    loansReportSlice,
    meetingsReportSlice,
    savingsReportSlice,
    membersReportSlice
} from '../app/modules/Reports/_redux/reportsSlice';
import { MembersReport } from '../app/modules/Reports/pages/MemberDetails';

export const rootReducer = combineReducers({
    auth: auth.reducer,
    roles: rolesSlice.reducer,
    users: usersSlice.reducer,
    groupTypes: groupTypesSlice.reducer,
    countries: countriesSlice.reducer,
    bankBranches: bankBranchesSlice.reducer,
    banks: banksSlice.reducer,

    branches: branchesSlice.reducer,
    branchesOptions: branchesOptionsSlice.reducer,
    groups: groupsSlice.reducer,
    loanProducts: loanProductsSlice.reducer,
    loanApplications: loanApplicationsSlice.reducer,
    lendings: lendingsSlice.reducer,
    groupMeetings: groupMeetingsSlice.reducer,
    agents: agentsSlice.reducer,
    cohortMembers: membersSlice.reducer,

    // ===== Start: Setup widgets =====
    systemProfile: systemProfileSlice.reducer,
    systemTheme: systemThemeSlice.reducer,
    systemServices: systemServicesSlice.reducer,
    systemUsers: systemUsersSlice.reducer,
    systemSettings: systemSettingsSlice.reducer,
    counties: countiesSlice.reducer,
    // ===== End: Setup widgets =======

    // ===== Start: Dashboard widgets =====
    fum: fumSlice.reducer,
    meetings: meetingsSlice.reducer,
    quickStats: quickStatsSlice.reducer,
    recentActivity: recentActivitySlice.reducer,
    depositsVsWithdrawals: depositsVsWithdrawalsSlice.reducer,
    loansStats: loansStatsSlice.reducer,
    communicationStats: communicationStatsSlice.reducer,
    groupSummaryStats: groupsSummaryStatsSlice.reducer,
    // ===== End: Dashboard widgets =======

    // ===== Start: Onboard groups slices =====
    groupInfo: groupInfoSlice.reducer,
    membersInfo: membersInfoSlice.reducer,
    groupContributions: groupContributionsSlice.reducer,
    groupLoans: groupLoansSlice.reducer,
    groupAccounts: groupAccountsSlice.reducer,
    groupConfirm: groupConfirmSlice.reducer,
    // ===== End: Onboard groups slices =====

    // ===== Start: Reports slices =====
    agentsReport: agentsReportSlice.reducer,
    branchesReport: branchesReportSlice.reducer,
    groupsReport: groupsReportSlice.reducer,
    meetingsReport: meetingsReportSlice.reducer,
    savingsReport: savingsReportSlice.reducer,
    loansReport: loansReportSlice.reducer,
    membersReport: membersReportSlice.reducer,
    // ===== End: Reports slices =====

    checkin: checkinSlice.reducer,
    
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
